import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/homePage",
    /*redirect:
         window.location.href.indexOf("echarts") !== -1
         ? "/echarts"
         : window.location.href.indexOf("smoothChart") !== -1
         ? "/smoothChart"
         : window.location.href.indexOf("pieChart") !== -1
         ? "/pieChart"
         : "/homePage",*/
  },
  {
    path: "/echarts",
    component: () => import("@/views/h5_chart.vue"),
  },
  {
    path: "/homePage",
    component: () => import("@/views/homePage.vue"),
  },
  {
    path: "/smoothChart",
    component: () => import("@/views/smoothChart.vue"),
  },
  {
    path: "/pieChart",
    component: () => import("@/views/pieChart.vue"),
  },
  {
    path: "/twoColumnChart",
    component: () => import("@/views/twoColumnChart.vue"),
  },
];

const router = new VueRouter({ routes });

export default router;
