<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import homePage from "@/views/homePage.vue";

export default {
  name: "App",
  components: {
    homePage,
  },
  mounted() {},
};
</script>

<style>
#app {
  height: 100%;
}
</style>
