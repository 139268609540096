<template>
  <div class="main_container">
    <div id="app_intro" class="main1">
      <div class="line"></div>
      <img alt="" class="intro" src="../assets/1.jpg" />
      <span class="text">
        方众云停APP旨在为车主及后台管理使用者提供咨询管理服务，主在展示停车行业咨询，最新停车设备展示运用，订单查询支付和停车设备施工、维修下单接单等功能。
      </span>
    </div>
    <div id="to" class="main2">
      <div class="line"></div>
      <!--      <p>WORKING WITH OBJECTS</p>-->
      <!--      <h1>使用对象</h1>-->
      <!--      <span class="to">-->
      <!--        使用对象：车主，停车场方，停车场管理人员，道闸厂商，施工维修人员-->
      <!--      </span>-->
      <img alt="" class="img" src="@/assets/2-2.jpg" />
    </div>
    <div id="display" class="main3">
      <div class="line"></div>
      <!--      <p>FUNCTIONAL DISPLAY</p>-->
      <!--      <h1>功能展示</h1>-->
      <img alt="" src="../assets/3.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "mainBody",
  data() {
    return {
      mao: "",
    };
  },
  watch: {
    mao(newVal, oldVal) {
      console.log(newVal, oldVal);
      console.log(this.$parent);
    },
  },
};
</script>

<style scoped>
.main_container {
  background-color: #fafafe;
  z-index: -100;
  position: relative;
  margin-top: -15px;
  width: 100%;
  text-align: center;
}

.main_container .main1 {
  position: relative;
  height: 980px;
  /*padding: 0 365px;*/
}

.main_container .main1 .line {
  border: 1px solid #0c151b;
  height: 170px;
  width: 0;
  position: absolute;
  /*left: 48%;*/
  /*left: 950px;*/
  left: 50%;
  top: -180px;
}

.main_container .main1 .intro {
  position: absolute;
  left: 50%;
  top: -130px;
  /*width: 100%;*/
  z-index: -1;
  transform: translate(-50%, 0);
}

.main_container .main1 .text {
  z-index: 999;
  color: #0c151b;
  position: absolute;
  /*right: 450px;*/
  right: 23%;
  top: 220px;
  width: 450px;
  font-size: 26px;
  letter-spacing: 1px;
  text-align: justify;
  line-height: 50px;
}

.main_container .main2 {
  position: relative;
  text-align: center;
  /*padding-top: 100px;*/
  padding: 100px 365px 0 365px;
}

.main_container .main2 .line {
  border: 1px solid #0c151b;
  height: 170px;
  width: 0;
  position: absolute;
  /*left: 48%;*/
  /*left: 930px;*/
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

.main_container .main2 p {
  font-size: 18px;
  color: #666666;
  /*margin-top: 100px;*/
  /*margin-left: -60px;*/
}

.main_container .main2 h1 {
  font-size: 69px;
  color: #414141;
  margin-top: -20px;
  /*margin-left: -60px;*/
  margin-bottom: 30px;
}

.main_container .main2 .to {
  font-size: 26px;
  color: #333333;
  margin-left: -30px;
}

.main_container .main2 .img {
  width: 100%;
  margin-top: 30px;
}

.main_container .main3 {
  position: relative;
  text-align: center;
  /*padding-top: 100px;*/
  /*background-color: #3c6dc9;*/
  /*padding: 100px 0 0 0 !important;*/
  margin-bottom: -5px;
}

.main_container .main3 .line {
  border: 1px solid #fff;
  height: 170px;
  width: 0;
  position: absolute;
  /*left: 48%;*/
  /*left: 950px;*/
  left: 50%;
  top: -50px;
  /*transform: translate(-50%, -50%);*/
}

.main_container .main3 p {
  font-size: 20px;
  /*color: #666666;*/
  /*margin-top: 100px;*/
  /*margin-left: -60px;*/
  color: #fff;
}

.main_container .main3 h1 {
  font-size: 65px;
  /*color: #414141;*/
  margin-top: -20px;
  margin-left: -60px;
  margin-bottom: 30px;
  color: #fff;
}

.main_container .main3 img {
  width: 100%;
}
</style>
