<template>
  <!-- 底部 start -->
  <div class="footer">
    <div class="left">
      <p class="com_name">江苏方众智慧城市停车管理有限公司</p>
      <p class="phone_number">电话：400-688-1333</p>
      <p class="address">地址：江苏省常州市新北区高新广场2号楼802</p>
      <p class="copyright">
        <span>版权所有 江苏方众智慧城市停车管理有限公司</span>
      </p>
      <p class="copyright to">
        Copyright 2022 FZTC All Rights Reserved 
        <a href="http://beian.miit.gov.cn/" target="_blank">
          苏ICP备2022040316号-2
        </a>
      </p>
    </div>
    <div class="right">
      <div class="connect">
        <img
          alt=""
          class="footer_img"
          src="https://fztc-cdn-1314927361.cos.ap-nanjing.myqcloud.com/home_imgs/footer/public.png"
        />
        <p class="conn_name">方众停车官方公众号</p>
      </div>
      <div class="connect">
        <img
          alt=""
          class="footer_img"
          src="https://fztc-cdn-1314927361.cos.ap-nanjing.myqcloud.com/home_imgs/footer/tik_top.jpg"
        />
        <p class="conn_name">方众停车官方抖音号</p>
      </div>
      <div class="connect">
        <img
          alt=""
          class="footer_img"
          src="https://fztc-cdn-1314927361.cos.ap-nanjing.myqcloud.com/home_imgs/footer/quick_worker.jpg"
        />
        <p class="conn_name">方众停车官方快手号</p>
      </div>
    </div>
  </div>
  <!-- 底部 end -->
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
/*底部 start*/
.footer {
  height: 240px;
  background-color: #444444;
  padding: 30px 140px 0 140px;
  box-sizing: border-box;
}

.footer .left {
  float: left;
  color: #d3d3d3;
}

.footer .left .address {
  margin-bottom: 30px;
}

.footer .left .copyright {
  font-size: 12px;
  //margin-top: 50px;
}

.footer .right {
  float: right;
  display: flex;
}

.footer .right .connect {
  color: #d3d3d3;
  margin-right: 100px;
}

.footer .right .connect:last-child {
  margin-right: 0;
}

.footer_img {
  width: 145px;
  height: 145px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
}

.to a {
  //margin-top: -10px; color: #d3d3d3; text-decoration: none;
}

.to a:hover {
  color: #317abf;
}

/*底部 end*/
</style>
